$dropzone-minheight: 245px;
$error-color: #a92222;

.FormUploader {
  
  .uploader-help-text {
    font-size: 0.75rem;
    margin: 0.3125rem 0 0 0;
    
    p {
      display: inline;
    }
  }
  
  .dropzone {
    padding: 0;
    margin: 0;
    border: 2px dashed $secondary;
    min-height: $dropzone-minheight;
    
    .alert {
      margin: 0;
      border-bottom: 2px dashed $secondary;
      font-size: 80%;
      
      p, i {
        margin: 0;
      }
      
      p {
        display: flex;
      }
      
      i {
        font-size: 20px;
        margin: 0 8px 0 0;
        color: inherit;
      }
    }
    
    &.dz-max-files-reached {
      pointer-events: none;
      cursor: default;
      
      .dz-remove {
        pointer-events: all;
      }
    }
    
    .dz-preview {
      .dz-error-message {
        top: 150px;  
      }
      
      .dz-error-mark {
        &:before {
          font-family: "Font Awesome 5 Free";
          position: absolute;
          content:"\f071";
          color: $error-color;
          font-size: 40px;
          top: -15px;
          left: 3px;
        }
        
        svg {
          display: none;
        }
      }
    }
    
    &.dz-started {
      .container-empty {
        display: none;
      }
    }
    
    .container-empty {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      min-height: $dropzone-minheight;
    }
    
    .dz-message {
      display: none;
    }
    
    i {
      font-size: 40px;
      margin-bottom: 10px;
    }
    
    .dropzone-main-text {
      font-weight: bold;
      margin: 0 0 10px 0;
      font-size: 18px;
      text-align: center;
    }
    
    .or {
      margin: 0 0 10px 0;
    }
    
  }
}
/* noto-sans-kr-regular - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans-kr-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/noto-sans-kr-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/noto-sans-kr-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/noto-sans-kr-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/noto-sans-kr-v13-latin-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-300 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/noto-sans-kr-v13-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/noto-sans-kr-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/noto-sans-kr-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/noto-sans-kr-v13-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/noto-sans-kr-v13-latin-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-sans-kr-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/noto-sans-kr-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/noto-sans-kr-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/noto-sans-kr-v13-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/noto-sans-kr-v13-latin-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
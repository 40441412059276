footer {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  overflow: hidden;
  margin-top: -30px;

  .nav {
    line-height: 16px;
    width: 172px;
    position: absolute;
    right: 0;
    padding-top: 20px;
    
    .nav-link {
      &:hover {
        text-decoration: underline;
        color: white;
      }
    }
  }
  
  .to-top-button {
    margin-top: 30px;
  }
  
  @include media-breakpoint-up(lg) {
    .logo {
      margin-left: 200px;
    }
    .nav {
      right: -123px;
    }
  }

  @include media-breakpoint-up(xl) {
    .logo {
      margin-left: 0;
    }
    .nav {
      right: 0;
    }
  }

  &:before {
    content: '';
    background: $background;
    position: absolute;
    top: -76%;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    transform: skewY(-5deg);
    border-bottom: 15px solid white;
  }

  > .container {
    position: relative;
    padding: 100px 0;

    > * {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      background: $secondary;
      position: absolute;
      top: 0;
      right: 810px;
      z-index: 1;
      width: 100%;
      height: 100%;
      transform: skewX(5deg);
      border-right: 15px solid #fff;
    }
  }



  .logo {
    filter: brightness(0) invert(1);
  }

  .SubNavigation {

    h4 {
      font-size: $font-size-base;
      font-weight: bold;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .nav-link {
        padding: 0;
        font-size: $font-size-sm;
      }
    }
  }

  .to-top {
    font-size: $font-size-sm;
    color: white;

    .fas {
      background: #fff;
      color: $primary;
      border-radius: 100%;
      font-size: 30px;
      width: 30px;
      height: 30px;
      text-align: center;
      margin-left: 10px;
    }

    a:hover {
      text-decoration: none;
      color: white;
    }
  }

  a {
    color: white;
  }
}
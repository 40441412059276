.job-detail-right-col {
  .VideoPlayer {
    margin-top: 30px;
    
    .videoWrapper {
      .info {
        background-color: rgba(255, 255, 255, 0.8);
        height: 100%;
        padding: 20px;
        width: 100%;
        
        h1, h2, h3, h4 {
          color: $primary;
          font-size: 1.25rem;
          font-weight: 400;
          margin-bottom: 15px;
          
          &:before {
            background-color: $primary;
            content: "";
            display: inline-block;
            height: 16px;
            margin-right: 10px;
            width: 5px;
          }
        }
        
        .playButton {
          i {
            margin: 0 10px 0 3px;
          }
        }
      }
    }
  }
}
.Course {
  ul {
    padding-left: 0;
    margin-top: 1rem;
    margin-bottom: 2rem;
    overflow: hidden;
    margin-left: 1.65rem;

    li {
      list-style-type: none;
      position: relative;
      padding-left: 3rem;
      margin-bottom: 1rem;

      &:before {
        font-family: "Font Awesome 5 Free",sans-serif;
        content: "\f138";
        color: $primary;
        position: absolute;
        left: 0;
      }
    }
  }
}


.course-teaser {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
  border-left: 5px solid $primary;
  margin-bottom: 30px;
  padding: 20px 40px;
  flex-direction: column;

  &:hover {
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.19);
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.19);
  }

  h3 {
    margin: 0 0 15px;
  }

  .date-place {
    display: flex;
    margin-bottom: 5px;
  }

  .content {
    background-color: white;
    padding-bottom: inherit;
    padding-top: inherit;
  }
}

.course-detail {
  .course-head {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #199494;
    margin-bottom: 30px;
    color: #fff;

    h1 {
      margin: 0 0 15px;
    }

    .date-place {
      display: flex;
      margin-bottom: 5px;
    }
  }
}
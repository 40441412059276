.white-box-job-detail {
  background-color: white;
  width: 100%;
  padding: 20px;
  display: block;
  margin: 40px 0 40px 0;
  p {
    color: $primary !important;
    font-weight: bold !important;
  }
}

.job-main-content {
  margin-top: 50px;
}

.job-heading {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: -30px;
  position: relative;
  color: #fff;
  background-color: $primary;
  
  &:before {
    content: "";
    display: block;
    height: 70px;
    position: absolute;
    transform: skewy(-2deg);
    width: 100%;
    z-index: 0;
    background-color: $primary;
    top: -35px;
  }

  > .container {
    margin-top: -20px;
  }
  
  h1 {
    color: white;
    font-weight: bold !important;
    padding: 10px 20px;
    display: inline-block;

    &:after {
      content:"m/w/d";
      font-weight: 300;
      margin-left: 15px;
      color: rgba(255,255,255,0.7);
      font-size: $h4-font-size;
    }
  }
  
  h2 {
    font-size: $font-size-lg;
    font-weight: 400;
    margin: 0 0 20px;
  }
  
  .headline-row {
    
    .badge {
      font-size: 1rem;
      i {
        color: $secondary;
        margin-right: 3px;
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }
    
    .button-row {
      a, button {
        i {
          margin-right: 5px;
        }
      }
    }
  }

  &.is--light {
    color: inherit;
    background-color: gray;

    .button-row {
      #share-options {
        .info-text {
          color: inherit;
        }
      }
    }

  }

  &.is--education-offer {
    .headline-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .image--container {
        width: 200px;
        height: 200px;
        min-width: 200px;
        background-position: center;
        background-size: cover;
        margin-right: 4rem;
        display: none;

        @media (min-width: 650px) {
          display: block;
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pill {
      text-align: center;
      font-size: 14px;
      line-height: 16px;
      padding: .5rem 1.5rem;
      min-width: 100px;

      @media (min-width: 500px) {
        font-size: 18px;
        line-height: 20px;
        min-width: 120px;
      }
    }

    h1 {
      padding: 0;
      margin-right: 1rem;
    }

    h1, span {
      display: inline-block;
    }
  }

  .info--person {
    margin-top: 1.5rem;

    span {
      font-size: 16px;
      display: block;
    }

    .name {
      font-weight: 500;
      font-size: 18px;
    }
  }

  .info--location {
    margin-top: 1.5rem;

    span {
      font-size: 16px;
      display: block;
    }

    .name {
      font-weight: 500;
      font-size: 18px;
    }
  }

  .button-row {
    margin-top: 2.5rem;
    
    .btn {
      min-height: auto;
      margin: 0 0 10px 0;
      
      &:not(first-of-child) {
        margin-right: 5px;
      }
    }

    #share-options {

      .btn {
        margin-bottom: .5rem;
      }

      .info-text {
        margin: 0;
        padding: 2.5rem 0 1rem 0;
        color: #fff;
        display: block;
        font-weight: 500;
      }
    }
  }

  &.has--image {
    .headline-row {
      .job-detail-title, .subtitle {
        display: inline-block;
        background-color: white;
        padding: .5rem 1.5rem;
      }


      .subtitle {
        display: inline-block;
        padding: .5rem 1.5rem;
        background-color: #199494;
        font-weight: 500;
        color: white;
      }
    }
  }

  .info--row {
    padding: 2rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    .info--item {
      font-size: 16px;
      margin-right: 2rem;
      i {
        margin-right: .5rem;
      }
    }
  }

  .item--id {
    font-size: 12px;
    font-weight: 400;
  }

  .subtitle {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0 0 0;
  }

  h1 {
    font-weight: 500;
    padding: 0;
    margin: 0;
    padding-bottom: .25rem;
    padding-top: 1rem;

    @include media-breakpoint-down(md) {
      font-size: $h3-font-size;
    }

    .job-detail-title {
      span {
        font-weight: 200;
      }
    }

    strong {
      font-weight: 400;
    }
  }

  p {
    font-weight: 200;
  }
}

.job-highlight-teaser {
  h3 {
    color: $secondary;
    font-size: $font-size-lg;
    font-weight: 700;
  }

  p {
    font-weight: 300;
    line-height: $font-size-base * 1.8;
    font-size: $font-size-base * 1.1;

    a {
      color: $secondary !important;
      border-bottom: 1px dotted $secondary !important;
    }

    strong {
      font-weight: 700;
      color: $primary;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.job-detail-content {
  margin-top: 30px;

  h2, h3, h4, h5 {
    color: $primary;
    font-size: $font-size-lg;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 30px;

    &:before {
      content: "";
      width: 5px;
      height: 16px;
      background-color: $primary;
      display: inline-block;
      margin-right: 10px;
    }
  }

  p {
    font-weight: 300;
    line-height: $font-size-base * 1.8;
    font-size: $font-size-base * 1.1;

    a {
      color: $secondary !important;
      border-bottom: 1px dotted $secondary !important;
      font-weight: 400;
    }

    strong {
      font-weight: 700;
      color: $primary;
    }
  }

  ul {
    padding-left: 0;

    li {
      list-style-type: none;
      padding-left: 22px;
      font-weight: 300;
      line-height: $font-size-base * 1.8;
      font-size: $font-size-base * 1.1;
      position: relative;

      &:before {
        font-family: "Font Awesome 5 Free";
        position: absolute;
        left: 0;
        content:"\f138";
        color: $secondary;
        font-size: $font-size-base * 0.9;
      }
    }
  }
}

.job-info-box {
  background-color: white;
  padding: 20px;
  
  &.job-information {
    p {
      margin-bottom: 6px;
    }
  }
  
  &:not(:first-of-type) {
    margin-top: 30px;
  }

  p {
    font-weight: 300;

    strong {
      font-weight: 400;
      color: $secondary;
    }
  }

  h4 {
    font-size: $font-size-lg;
    font-weight: 400;
    margin-bottom: 15px;
    color: $primary;

    &:before {
      content: "";
      width: 5px;
      height: 16px;
      background-color: $primary;
      display: inline-block;
      margin-right: 10px;
    }
  }
}

.job-detail-right-col {
  .contact-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .contact-image {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background-position: center;
      background-size: cover;
      margin-right: 10px;
    }
    
    .contact-name {
      margin: 0;
    }
  }
}

.job--header-image {
  height: 350px;
  background-size: cover;
  margin-bottom: 30px;
}

#bewerbungsformular {
  .form-headline {
    margin: 15px 0 50px 0;
  }

  .form-group label {
    //color: $primary;
  }
  
  .upload-button {
    min-height: 50px;
    padding: 0 20px;
  }
  
  .Group {
    margin-bottom: 10px;
  }
  
}
.content {
  background-color: $background;
  padding-top: 100px;
  padding-bottom: 50px;
}

.intro-homepage {
  h1 {
    background-color: $secondary;
    color: white;
    font-size: $h3-font-size;
    padding: 10px 20px;
    display: inline-block;
  }

  p {
    font-size: $font-size-lg;
    font-weight: 300;
    margin-top: 10px;
  }

  margin-bottom: 30px;
}
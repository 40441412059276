.TeaserSlider {
  margin-top: -100px;
  margin-bottom: 30px;

  .slides_container {
    display: none;

    &.slick-initialized {
      display: block;
    }

    .bg {
      padding: 180px 0;
      background-size: cover;
      background-position: center;

      &, * {
        color: #fff;
      }

      a {
        @extend .stretched-link;
        border-left: 3px solid #fff;
        padding: 10px 0 10px 10px;
        display: inline-block;
        font-weight: $font-weight-light;
      }
    }

    .slick-arrow {
      border: 0;
      outline: 0;
      background: #fff;
      padding: 0 15px;
      position: absolute;
      top: calc(50% - 15px);
      left: 0;
      z-index: 10;
      color: $primary;
      font-size: 30px;

      &.slick-next {
        right: 0;
        left: auto;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 0 0 10px 0;

      button {
        color: transparent;
        background: #fff;
        margin: 0 5px;
        height: 6px;
        width: 50px;
        border: 0;
        outline: 0;
        opacity: .5;
      }

      .slick-active {

        button {
          opacity: 1;
        }
      }
    }
  }
}
.form-group {
  label {
    font-size: $font-size-sm;
    color: $secondary;

    .req {
      color: $body-color;
      font-weight: 300;
    }
  }

  .form-control {

    &:focus {
      border-bottom: 3px solid $primary;
      outline: none;
      box-shadow: none;
    }
  }
}

#bewerbungsformular, .initiativbewerbung:not(.wrapper) {
  background-color: white;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 20px;

  h2 {
    color: $secondary;
    font-size: $font-size-lg;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

.privacy {
  .label {
    display: none;
  }

  label {
    color: $body-color;

    a {
      border-bottom: 1px dotted $primary;

      &:hover {
        text-decoration: none;
        color: $secondary;
        border-bottom: 1px dotted $secondary;
      }
    }
  }
}

.upload-btn-wrapper {
  border-bottom: 1px solid $background;
  padding-bottom: 15px;

  input {
    display: none;
  }

  .upload-button {
    margin-right: 15px;
    background-color: $background;
  }
}

.FormUploader {

  .dropzone {
    border: 1px solid $background;
    background: $background;

    &.is-invalid {
      border-color: $danger;
    }

    &.is-valid {
      border-color: $success;
    }

    .dz-image-preview {
      background: transparent;
    }
  }

  .filetypes {
    text-transform: uppercase;
  }
}
.JobBox {
  .Job {
    background-color: white;
    display: flex;
    margin-bottom: 30px;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &:hover {
      -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.19);
      box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.19);
    }


    .left {
      flex: 0 0 300px;
      background-color: $primary;
      padding: 20px;
      text-align: center;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(md) {
        display: block;
        padding: 15px 10px;
        text-align: left;
      }

      h2 {
        font-size: $h4-font-size;
        font-weight: 400;

        @include media-breakpoint-down(md) {
          font-size: $font-size-lg;
          display: inline-block;
        }

        a {
          color: white;
          text-decoration: none;

          &:hover {
            text-decoration: none;
            color: white;
          }
        }

        &:after {
          display: block;
          content:"m/w/d";
          font-weight: 300;
          font-size: $font-size-lg;
          margin-top: 10px;

          @include media-breakpoint-down(md) {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }

    .right {
      padding: 50px 30px;
      flex: 0 0 calc(100% - 300px);

      @include media-breakpoint-down(md) {
        padding: 15px 10px;
      }

      .job-infos {
        display: block;
        h3 {
          font-size: $font-size-lg;
          font-weight: 400;
          padding-bottom: 15px;
        }

        .row {
          & + .row {
            margin-top: 20px;

            @include media-breakpoint-down(md) {
              margin-top: 0;
            }
          }
        }

        .info-col {
          position: relative;

          @include media-breakpoint-down(md) {
            margin-top: 10px;
          }

          .fa {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $secondary;
            position: absolute;
            left: 15px;
            box-sizing: border-box;
            color: white;
            font-size: $font-size-lg;
          }

          .job-info {
            padding-left: 50px;

            p {
              margin: 0;
              font-weight: 400;
              color: $secondary;

              &.headline {
                font-weight: 300;
                color: $body-color;
              }
            }
          }
        }
      }
    }
  }
}
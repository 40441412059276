header {
  position: relative;
  z-index: 10;

  .top-nav {
    position: relative;

    &:before {
      content: '';
      display: block;
      background: $secondary;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 40%;
      border-right: 15px solid #fff;
      transform: skewX(10deg);
    }

    .nav {
      margin: 0 12px 0 0;
      
      .nav-link {
        color: #fff;
        font-size: $font-size-sm;
      } 
    }
  }

  .navbar-nav {
    .nav-item {
      &.first {
        .name {
          display: none;
        }
      }
    }
  }
  
  .navbar-brand {

    img {
      max-width: 200px;
    }
  }

  &:after {
    position: absolute;
    bottom: -16px;
    display: block;
    z-index: -1;
    content: '';
    height: 100%;
    width: 100%;
    background-color: #fff;
    transform: skewy(-2deg);
  }

  + div {
    margin-top: -20px;
  }
}

@include media-breakpoint-down(md) {
  .navbar-nav {
    background-color: $background;
    padding: 15px;
    margin-top: 10px;
  }
}
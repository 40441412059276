.Group {
  .Article {
    &.phone {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15px;

      @media(max-width: 767px) {
        justify-content: center;
      }

      i {
        font-size: 18px;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        background-color: $primary;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 3px;
      }
    }
    &.envelope {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15px;

      @media(max-width: 767px) {
        justify-content: center;
      }

      i {
        font-size: 18px;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        background-color: $primary;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.article-fa-icons {
      .fa {
        color: white;
      }

      p {
        padding-top: 0;
      }
    }
    
  }
  .Group {
    i, p {
      padding: 0;
      margin: 0;
    }
  }
}

.Article {
  &.quicklinks-article {
    &.wrapper {
      padding: 80px 0;
    }
    
    ul {
      padding-left: 0;
      margin-bottom: 0;

      @media(min-width: 768px) {
        column-count: 2;
      }

      li {
        list-style: none;

        a {
          @extend .btn;
          @include button-variant(#fff, #fff, darken(#fff, 7.5%), darken(#fff, 7.5%), #fff, #fff);
          display: block;
          margin-bottom: 15px;
        }
      }
    }
  }
  
  &.contact-article-box {
    .button-group {
      a {
        margin-top: 0 !important;
      }
    }
    
    &.wrapper {
      padding: 60px 0;
    }

    h1, h2, h3 {
      font-weight: bold;
      text-align: center;
      margin-bottom: 25px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;

      li {
        list-style: none;
        margin: 0 15px 0 15px;
        
        p {
          margin: 0;
        }
      }
    }
  }
}

.article--features-headline {
  p {
    margin-bottom: 0;
  }
  
  &.wrapper {
    h1, h2, h3 {
      font-weight: bold;
      padding-top: 60px;
    }
  }
}

.agreement-box {
  h1, h2, h3 {
    font-weight: bold;
    padding-bottom: 30px;
  }
}

.Group {
  &.wrapper {
    &.default-group-padding {
      padding: 60px;
    } 
  }

  &.article--features-group {
    &.wrapper {
      padding: 30px 0;
    }
    
    .Article {
      ul {
        padding-left: 0;
        margin-top: 1rem;
        margin-bottom: 2rem;
        overflow: hidden;
        margin-left: 1.65rem;

        li {
          list-style-type: none;
          position: relative;
          padding-left: 2.5rem;
          margin-bottom: 1rem;

          &:before {
            font-family: "Font Awesome 5 Free";
            content: "\f138";
            color: white;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
}

.blockquote {
  padding: 14px 28px;
  margin: 0 0 28px;
  font-size: 22.5px;
  border-left: 5px solid $primary;
}

.group--employee-opinion {
  .Group {
    padding: 60px 0;
    
    .image-container {
      height: 250px;
      background-size: cover;
      background-position: center;
    }

    .container {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .Article {
    
    .container {
      padding: 0;
      align-items: center;
      display: flex;
    }
    
    
    blockquote {
      margin-left: 20px;
      border-left: 5px solid $primary;
      
      p {
        margin-left: 24px;

      }
    }

    > p {
      margin-left: 50px;
    }
  }
}

.article-box {
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &.first-article {
    h2 {
      margin-top: 50px;
    }
  }
  
  &.bg-primary, &.bg-secondary {
    color: white;
  }
}

.first-article {
  margin-top: -100px !important;
  .bg {
    padding-top: 140px;
  }
}

.mt-70 {
  margin-top: 70px !important;
}

.mt--100 {
  margin-top: -100px !important;
}

.article-bg {

  h2 {
    font-weight: 300;
  }

  > .bg {
    background-size: cover;
    padding-top: 80px;
    padding-bottom: 80px;

    &, * {
      color: #fff;
    }
  }
}

.container-grouped {

  h2 {
    font-weight: 300;
  }

  .c1 {

    .container {
      border-right: 8px solid $background;
    }
  }

  .c2 {

    .container {
      border-left: 8px solid $background;
    }
  }

  .container {
    padding-top: 100px;
    padding-bottom: 100px;
    height: 100%;
  }
}

.button-group {
  a {
    @extend .btn;
    margin-top: 2rem;
  }
  
  &.primary {
    a {
      @extend .btn-primary;
    } 
  }

  &.secondary {
    a {
      @extend .btn-secondary;
    }
  }
}
$font-family-base: 'Noto Sans KR';
$font-weight-bolder: 700;

$primary: #0090DF;
$secondary: #572381;
$background: #efefef;

$body-color: #4D4D4D;

$line-height-base: 1.6;
$headings-line-height: 1.4;

$border-radius: 0;
$card-border-color: #fff;

$navbar-nav-link-padding-x: 1.3rem;

$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

$input-bg: $background;
$input-border-color: $background;
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?bkh19l');
  src:  url('../fonts/icomoon.eot?bkh19l#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?bkh19l') format('truetype'),
  url('../fonts/icomoon.woff?bkh19l') format('woff'),
  url('../fonts/icomoon.svg?bkh19l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Article {
  i {
    &.icon-pflege:before {
      content: "\e900";
      color: #324c5e;
    }
    &.icon-aerztlicher-dienst:before {
      content: "\e901";
      color: #324c5e;
    }
    &.icon-verwaltung:before {
      content: "\e902";
      color: #324c5e;
    }
    &.icon-ausbildung:before {
      content: "\e909";
      color: #324c5e;
    }
    &.icon-medizintechnik:before {
      content: "\e904";
      color: #324c5e;
    }
    &.icon-therapie:before {
      content: "\e90a";
      color: #324c5e;
    }
    &.icon-medizinische-fachberufe:before {
      content: "\e90b";
      color: #324c5e;
    }
    &.icon-paedagogik:before {
      content: "\e90c";
      color: #324c5e;
    }
  }
}
.button {
  a, button, input {
    @extend .btn;
  }

  &.button-primary {
    a, button, input {
      @extend .btn-primary;
    }
  }

  &.button-default {
    a, button, input {
      @extend .btn-light;
    }
  }

  &.button-block {
    a, button, input {
      @extend .btn-block;
    }
  }
}

.btn {
  &:focus {
    box-shadow: none;
  }
  
  /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
  &.btn-white {
    @include button-variant(#fff, #fff, darken(#fff, 7.5%), darken(#fff, 7.5%), #fff, #fff);
  }
}

li {
  &.display-button {
    a {
      @extend .btn-light;
    }
  }
}
.jobgroup--box {
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-out;

  &.is--primary {
    background-color: $primary;
    color: #fff;
    margin-bottom: 3rem;
    padding: 3rem;
    min-height: 220px;

    p {
      font-family: Noto Sans KR;
      font-weight: bold;
    }
    
    .icon-placeholder {
      width: 100px;
      background-color: #ffffff5e;
      height: 100px;
    }

    &.article-icon {
      transition: all 0.2s ease-out;

      &:hover {
        background-color: darken($primary, 10%);
        i {
          transform: translateY(-.5rem) scale(1.1);
        }
      }

      i {
        display: block;
        transition: all 0.2s ease-out;

        &:before {
          font-size: 90px;
          color: white;
        }
      }

      a {
        color: white;
        font-weight: bold;
        display: block;
        padding-top: .5rem;

        &:hover {
          text-decoration: none;
        }
      }
    }

  }

  &.is--light {
    background-color: $background;
    //color: $text-color;
    margin-bottom: 3rem;
    padding: 3rem;
    min-height: 220px;

    .icon-placeholder {
      width: 100px;
      background-color: #ffffff;
      height: 100px;
    }

    &.article-icon {
      transition: all 0.2s ease-out;

      i {
        display: block;

        &:before {
          font-size: 90px;
          color: $primary;
        }
      }

      p {
        color: $primary;
        font-weight: bold;
        display: block;
        padding-top: .5rem;
      }

      a {
        color: $primary;
        font-weight: 500;
        font-size: 20px;
        display: block;
        padding-top: .5rem;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  h2 {
    font-size: 20px;
    text-align: center;
    margin: 0;
    font-weight: 500;
    padding-top: 2rem;
  }

  .jobbgroup--box-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

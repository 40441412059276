.JobFilter {
  padding-bottom: 30px;

  @include media-breakpoint-down(md) {
    .row {
      display: block;
    }
  }

  .buttons {
    padding-top: 32px;
    display: flex;

    @include media-breakpoint-down(md) {
      padding-top: 0;
    }

    .button-primary {
      margin-right: 15px;
      flex-grow: 1;
    }
  }

  .form-control {
    background: #fff;
  }
}